<template>
  <div class="tile tile-default">
    <div class="tile-spacer" />
    <div>
      <img
        v-lazy="backgroundImageUrl"
        class="tile-img-bg"
      >
      <div class="tile-content">
        <h4 class="tile-title" v-if="$slots.title">
          <slot name="title" />
        </h4>
        <slot name="content" />
      </div>
    </div>
  </div>
</template>

<script>
import config from 'config';

export default {
  props: {
    backgroundColor: {
      type: String,
      required: false,
      default: null
    },
    backgroundImageUrl: {
      type: String,
      required: false,
      default: null
    }
  },
  computed: {
  }
};
</script>

<style lang='scss' scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-partner: color(partner);
$color-darken-partner-10: color(darken-partner-10);

.tile {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.tile-default {
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity .15s ease-in-out;
  }

  &:hover:before,
  &:focus:before {
    opacity: 0;
  }
}

.tile-spacer {
  padding-top: 100%;
}

.tile-clr-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}

.tile-img-bg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;

  width: auto;
  height: 100%;

  display: block;
}

.tile-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  z-index: 1001;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;

  text-align: center;

  box-sizing: border-box;
  padding: 16px;
}

.tile-title {
  align-items: center;
  justify-items: center;
  color: #ffffff;
  font-size: 24px;
  font-weight: 100;
  line-height: 1;
  margin: 0;
}

@media (max-width: 75em) {
}

@media (max-width: 64em) {
}
</style>
