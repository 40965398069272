import { mapGetters } from 'vuex';
import { DATE_FROM, DATE_TO, PARTNER_TYPE } from '../../src/constants/FilterVariantTypes';

export default {
  computed: {
    ...mapGetters({
      getSource: 'cart/getSource',
      getReservations: 'reservations/getCurrentDates'
    })
  },
  data () {
    return {
      fixedUrlQuery: this.getFixedUrlQuery()
    }
  },
  mounted () {
    this.fixedUrlQuery = this.getFixedUrlQuery()
  },
  methods: {
    getFixedUrlQuery () {
      const params = {}
      if (this.getReservations && !!this.getReservations.to && !!this.getReservations.from) {
        params[DATE_FROM] = this.getReservations.from
        params[DATE_TO] = this.getReservations.to
      }
      if (this.getSource && this.getSource.id) {
        params[PARTNER_TYPE] = this.getSource.id
      }
      return params;
    }
  },
  watch: {
    getSource () {
      this.fixedUrlQuery = this.getFixedUrlQuery()
    },
    getTotals () {
      this.fixedUrlQuery = this.getFixedUrlQuery()
    }
  }
};
