<template>
  <div class="row">
    <div v-for="(tile, index) in partnerTiles" :key="index" :id="index" class="col-md-4 col-sm-6 pb5">
      <a v-if="isExternalLink(tile.link)" :href="tile.link" target="_blank">
        <icon-link-tile :class="getTileClass(tile.backgroundColor)" :icon="bannerIconList[index]">
          <template slot="title" class="tile-title">
            <div v-html="tile.text" />
          </template>
        </icon-link-tile>
      </a>
      <router-link v-else :to="tile.link">
        <icon-link-tile :class="getTileClass(tile.backgroundColor)" :icon="bannerIconList[index]">
          <template slot="title" class="tile-title">
            <div v-html="tile.text" />
          </template>
        </icon-link-tile>
      </router-link>
    </div>
  </div>
</template>

<script>
import IconLinkTile from 'theme/components/theme/anyrent/IconLinkTile';
import { mapState } from 'vuex'

export default {
  components: { IconLinkTile },
  props: {
    partnerTiles: {
      type: Array,
      required: true,
      default: () => ([])
    }
  },
  computed: {
    ...mapState({
      bannerIconList: state => state.ui.bannerIconList
    })
  },
  data () {
    return {
    }
  },
  methods: {
    isSSR () {
      return typeof window === 'undefined';
    },
    getTileClass (backgroundColor) {
      switch (backgroundColor) {
        case 'primary':
          return 'static-tiles';
        case 'secondary':
          return 'tile-default-coloring';
        default: return '';
      }
    },
    isExternalLink (link) {
      return /^(https?:\/\/|www\.)/.test(link);
    }
  },
  mounted () {
    if (!this.bannerIconList.length) {
      // ie. when comming from /error page
      this.$store.dispatch('ui/loadBannerIconList', this.partnerTiles)
    }
  },
  async serverPrefetch () {
    return await this.$store.dispatch('ui/loadBannerIconList', this.partnerTiles)
  }
};
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';

$color-anyrent: color(anyrent);
$color-darken-anyrent-10: color(darken-anyrent-10);
$color-partner: color(partner);
$color-darken-partner-10: color(darken-partner-10);

.custom-red-bg {
  width: 100%;
  position: relative;
  overflow: hidden;

  background-color: $color-anyrent;

  :hover,
  :focus {
    background-color: $color-darken-anyrent-10;
  }
}

.static-tiles {
  width: 100%;
  position: relative;
  overflow: hidden;
  transition: opacity .15s ease-in-out;
  background-color: $color-partner;
  &:hover,
  &:focus {
    background-color: $color-darken-partner-10;
    opacity: 0.8;
  }
}

.tile-default-coloring {
  background-color: #b5ab9e;
  transition: opacity .15s ease-in-out;
  &:hover,
  &:focus {
    opacity: 0.8;
  }
}
</style>
