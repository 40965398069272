<template>
  <div id="home">
    <div v-for="(component, index) in componentList" :key="index">
      <section
        v-if="isSearchBar(component)"
        class="container pb30"
      >
        <div class="col-md-12 start-xs">
          <search-bar
            :categoryList="component.categoryList"
            :subfilters="false"
            :current-category="null"
          />
        </div>
      </section>

      <div
        v-if="isTileRow(component)"
        class="container"
      >
        <advertising-tiles :partnerTiles="component.tileList" />
      </div>

      <section
        v-if="isBannerRow(component)"
        class="pt10 pb25"
      >
        <div class="container hidden-xs">
          <static-page-tiles :partnerTiles="component.bannerList" />
        </div>
      </section>

      <main-slider
        v-if="isSlider(component)"
        :partnerSlides="component.slideList"
      >
        <slot name="" />
      </main-slider>
    </div>
  </div>
</template>
<script>
// query constructor
import { isServer, onlineHelper } from '@vue-storefront/core/helpers'
import LazyHydrate from 'vue-lazy-hydration'

// Core pages
import Home from '@vue-storefront/core/pages/Home'
// Theme core components
import MainSlider from 'theme/components/core/blocks/MainSlider/MainSlider'
import SearchBar from 'theme/components/theme/anyrent/SearchBar/SearchBar'
// Theme local components
import {Logger} from '@vue-storefront/core/lib/logger'
import {mapGetters} from 'vuex'
import config from 'config'
import {registerModule} from '@vue-storefront/core/lib/modules'
import {RecentlyViewedModule} from '@vue-storefront/core/modules/recently-viewed'
import AdvertisingTiles from '../components/theme/blocks/Home/AdvertisingTiles'
import StaticPageTiles from '../components/theme/blocks/Home/StaticPageTiles'
import NoSSR from 'vue-no-ssr'
import { selfCanonical } from '../helpers/meta/selfCanonical';
import HOMEPAGE_QUERY from '../../../../src/themes/default/graphql/strapi/queries/homepage.gql'
import i18n from '@vue-storefront/i18n'

const homepageComponentTypeMap = {
  ComponentHomepageSearchBar: 'ComponentHomepageSearchBar',
  ComponentHomepageTileRow: 'ComponentHomepageTileRow',
  ComponentHomepageBannerRow: 'ComponentHomepageBannerRow',
  ComponentHomepageSlider: 'ComponentHomepageSlider'
}

const transformHomepageDataQueryResponse = (homepageQueryResponse) => {
  const componentList = homepageQueryResponse?.data?.homepages?.data[0]?.attributes?.Homepage
    .map((component) => {
      switch (component.__typename) {
        case homepageComponentTypeMap.ComponentHomepageSearchBar:
          return {
            type: component.__typename,
            categoryList: (component.Category || []).map(({ id, Link, Name }) => ({
              id,
              link: Link,
              name: Name
            }))
          };

        case homepageComponentTypeMap.ComponentHomepageTileRow:
          if (!component.Tile?.length) {
            return null;
          }

          return {
            type: component.__typename,
            tileList: component.Tile.map(({ id, Text, Link, ButtonText, BackgroundImage, ImageAltText, BackgroundColor }) => ({
              id,
              text: Text,
              link: Link,
              buttonText: ButtonText,
              backgroundImageUrl: BackgroundImage?.data?.attributes.url,
              imageAltText: ImageAltText,
              backgroundColor: BackgroundColor
            }))
          }

        case homepageComponentTypeMap.ComponentHomepageBannerRow:
          if (!component.Banner?.length) {
            return null
          }

          return {
            type: component.__typename,
            bannerList: component.Banner.map(({ id, Text, Link, Icon, IconAltText, BackgroundColor }) => ({
              id,
              text: Text,
              link: Link,
              iconUrl: Icon?.data?.attributes.url,
              iconAltText: IconAltText,
              backgroundColor: BackgroundColor
            }))
          }

        case homepageComponentTypeMap.ComponentHomepageSlider:
          if (!component.Slide?.length) {
            return null;
          }

          return {
            type: component.__typename,
            slideList: component.Slide.map(({ id, Title, Link, Subtitle, ImageAltText, BackgroundImage }) => ({
              id,
              title: Title,
              link: Link,
              subtitle: Subtitle,
              imageAltText: ImageAltText,
              backgroundImageUrl: BackgroundImage?.data?.attributes.url
            }))
          }

        default:
          return null;
      }
    })
    .filter((component) => !!component);

  return componentList;
}

export default {
  data () {
    return {
      loading: true,
      componentList: []
    }
  },
  mixins: [Home],
  components: {
    SearchBar,
    AdvertisingTiles,
    StaticPageTiles,
    MainSlider,
    'no-ssr': NoSSR
  },
  methods: {
    displayPartnerPages () {
      return this.$ssrContext?.partnerData.displayPartnerPages || config.partnerData.displayPartnerPages;
    },
    fetchHomepageData (partnerId) {
      return new Promise(async (resolve, reject) => {
        try {
          const homepageQueryResponse = await this.$apollo.query({
            query: HOMEPAGE_QUERY,
            variables: {
              'filters': {
                'Partner': {
                  'Name': {
                    'eq': partnerId
                  }
                }
              }
            }
          });

          this.componentList = transformHomepageDataQueryResponse(homepageQueryResponse);
          resolve();
        } catch (error) {
          Logger.error('Cannot load data on homepage: ' + error);
          reject();
        }
      });
    },
    isSearchBar (component) {
      return component.type === homepageComponentTypeMap.ComponentHomepageSearchBar;
    },
    isTileRow (component) {
      return component.type === homepageComponentTypeMap.ComponentHomepageTileRow;
    },
    isBannerRow (component) {
      return component.type === homepageComponentTypeMap.ComponentHomepageBannerRow;
    },
    isSlider (component) {
      return component.type === homepageComponentTypeMap.ComponentHomepageSlider;
    }
  },
  computed: {
    ...mapGetters('user', ['isLoggedIn']),
    ...mapGetters('homepage', ['getEverythingNewCollection']),
    categories () {
      const cats = this.getCategories
      return cats
    },
    isOnline () {
      return onlineHelper.isOnline
    },
    isLazyHydrateEnabled () {
      return config.ssr.lazyHydrateFor.some(
        field => ['homepage', 'homepage.new_collection'].includes(field)
      )
    }
  },
  beforeCreate () {
    registerModule(RecentlyViewedModule)
  },
  async beforeMount () {
    if (this.$store.state.__DEMO_MODE__) {
      const onboardingClaim = await this.$store.dispatch('claims/check', { claimCode: 'onboardingAccepted' })
      if (!onboardingClaim) { // show onboarding info
        this.$bus.$emit('modal-toggle', 'modal-onboard')
        this.$store.dispatch('claims/set', { claimCode: 'onboardingAccepted', value: true })
      }
    }
  },
  mounted () {
    this.fetchHomepageData(config.partnerData.id);
    if (!this.isLoggedIn && localStorage.getItem('redirect')) this.$bus.$emit('modal-show', 'modal-signup')
  },
  serverPrefetch () {
    const { id: partnerId } = this.$ssrContext.partnerData;
    return this.fetchHomepageData(partnerId);
  },
  watch: {
    isLoggedIn () {
      const redirectObj = localStorage.getItem('redirect')
      if (redirectObj) this.$router.push(redirectObj)
      localStorage.removeItem('redirect')
    }
  },
  async asyncData ({ store, route }) { // this is for SSR purposes to prefetch data
    Logger.info('Calling asyncData in Home (theme)')()

    await Promise.all([
      store.dispatch('homepage/fetchNewCollection'),
      store.dispatch('promoted/updateHeadImage'),
      store.dispatch('promoted/updatePromotedOffers')
    ])
  },
  beforeRouteEnter (to, from, next) {
    if (!isServer && !from.name) { // Loading products to cache on SSR render
      next(vm =>
        vm.$store.dispatch('homepage/fetchNewCollection').then(res => {
          vm.loading = false
        })
      )
    } else {
      next()
    }
  },
  metaInfo () {
    return {
      title: this.$route.meta.title || i18n.t('Home Page'),
      meta: this.$route.meta.description ? [{ vmid: 'description', name: 'description', content: this.$route.meta.description }] : [],
      link: [
        selfCanonical(this.$route)
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
.new-collection {
  @media (max-width: 767px) {
    padding-top: 0;
  }
}
</style>
