<template>
  <section class="main-slider w-100 bg-cl-th-accent cl-white mb40">
    <carousel
      v-if="!isSSR"
      :per-page="1"
      :navigation-enabled="true"
      pagination-active-color="#828282"
      :autoplay="true"
      :loop="true"
      :speed="700"
      :autoplay-timeout="5500"
      :mouse-drag="false"
    >
      <slide v-for="(slide, index) in partnerSlides" :key="index">
        <div
          class="container w-100 image-align-left"
          :style="{ 'background-image': 'url(' + slide.backgroundImageUrl + ')' }"
          :alt="slide.imageAltText"
        >
          <div class="row">
            <div class="col-xs-12">
              <div class="container pb60">
                <div class="row middle-xs end-xs">
                  <div class="col-md-8 col-lg-6">
                    <router-link :to="slide.link">
                      <div class="bg-cl-th-anyrent background_box">
                        <h1 class="title mt0 mb30 align-center" data-testid="mainSliderTitle">
                          {{ slide.title }}
                        </h1>
                        <p
                          class="line mb0 serif h3 align-left"
                          data-testid="mainSliderSubtitle"
                        >
                          {{ slide.subtitle }}
                        </p>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </slide>
    </carousel>

    <div
      v-else
      class="container w-100 image-align-left"
      <!-- :style="{ 'background-image': 'url(' + slides[0].image + ')' }" --
    >
      >
      <div class="row">
        <div class="col-xs-12">
          <div class="container pb60">
            <div class="row middle-xs end-xs">
              <div class="col-md-8 col-lg-6">
                <div class="bg-cl-th-anyrent background_box">
                  <!--
                    <p
                      class="subtitle mb0 serif h3 align-center"
                      data-testid="mainSliderSubtitle"
                    >
                      {{ slides[0].subtitle }}
                    </p>
                    <h1 class="title mt0 mb30 align-center" data-testid="mainSliderTitle">
                      {{ slides[0].title }}
                    </h1>
                    <p
                      v-if="slides[0].subtitle2 != ''"
                      class="subtitle subtitle2 mb0 serif h3 align-center"
                      data-testid="mainSliderSubtitle"
                    >
                      {{ slides[0].subtitle2 }}
                    </p>
                    <p
                      class="line mb0 serif h3 align-left"
                      data-testid="mainSliderSubtitle"
                    >
                      <span v-html="slides[0].line" />
                    </p>
                    <p
                      class="line mb0 serif h3 align-left"
                      data-testid="mainSliderSubtitle"
                      v-if="slides[0].line2!=''"
                    >
                      {{ slides[0].line2 }}
                    </p>
                    <p
                      class="line mb0 serif uppercase h3 align-left"
                      data-testid="mainSliderSubtitle"
                      v-if="slides[0].line3!=''"
                    >
                      {{ slides[0].line3 }}
                    </p>
                  -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import sliderData from 'theme/resource/slider.json';
import config from 'config';
import { Slide, Carousel } from 'vue-carousel';

export default {
  data () {
    return {
      isSSR: true,
      currentSlide: 1
    };
  },
  components: {
    Slide,
    Carousel
  },
  props: {
    partnerSlides: {
      type: Array,
      required: true,
      default: () => ([])
    }
  },
  methods: {
    // resizeImage (url) {
    //   const width = 1800;
    //   const height = 1299;
    //   return `${config.api.url}/img/?url=${url}&width=${width}&height=${height}&action=resize`;
    // },
    // isSSR () {
    //   return typeof window === 'undefined';
    // }
  },
  mounted () {
    this.isSSR = false;
    setInterval(() => {
      this.currentSlide = (this.currentSlide + 1) % (this.partnerSlides.length);
    }, 5000);
  }
};
</script>
<style lang='scss'>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';

.main-slider {

  @media (max-width: 767px) {
    display: none;
  }

  .VueCarousel-pagination {
    position: absolute;
    bottom: 15px;
  }

  .VueCarousel-dot--active .VueCarousel-dot-inner {
    border: 2px solid color(anyrent, $colors-theme);
    margin-top: -2px;
  }

  .VueCarousel-pagination {
    position: absolute;
    bottom: -40px;
  }

  button.VueCarousel-dot-button {
    background-color: grey !important;
  }

  button.VueCarousel-dot-button:focus {
    outline: 0;
  }

  .VueCarousel-dot--active button.VueCarousel-dot-button {
    background-color: color(anyrent, $colors-theme) !important;
  }

  button {
    color: #fff;
    width: 148px;
    height: 38px;
    background: url("/assets/button-outline-white.svg");
    border: none;
    font-size: 21px;
    font-weight: 800;

    &:hover {
      color: rgb(204, 203, 203);
    }
  }

  span.accent {
    font-family: 'TTCommons-Bold';
  }

  .container.w-100.image-align-left {
    background-position: left;
  }
}

</style>
<style lang='scss' scoped>

button a {
  color: white;
}

button a:hover {
  text-decoration: none;
}

h1 {
  font-size: 63px;
  margin: 0px;
  text-align: left;
  font-weight: bold;
  font-family: 'TTCommons-Light';
  line-height: 1em;
}

.line, .subtitle {
  font-family: 'TTCommons-Light';
  // text-transform: lowercase;
  text-align: left;
  font-size: 36px;
  line-height: 1em;
}

.subtitle {
  font-size: 73px;
  margin-top: 2%;

  &.subtitle2 {
    font-size: 67px;
    margin-top: 0;
    line-height: 0.7em
  }
}

.line {
  font-size: 37px;
  line-height: 1em;
}

.main-slider {
  height: 420px;
}

.container {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.row {
  height: 420px;
}

a .background_box {
  color: #fff;
  padding-top: 4%;
  padding-bottom: 8%;
  padding-right: 4%;
  padding-left: 4%;
}

@media (max-width: 75em) {
  .main-slider {
    height: 316px;
  }
  .title {
    font-size: 48px;
  }
  .subtitle {
    font-size: 48px;

    &.subtitle2 {
      font-size: 40px;
      margin-top: 0;
      line-height: 0.7em
    }
  }
  .line {
    font-size: 32px;
  }
  .row {
    height: 316px;
  }
}

@media (max-width: 64em) {
  .main-slider {
    height: 359px;
  }
  .container {
    background-position: left;
  }
  .title {
    font-size: 47px;
  }
  .subtitle {
    font-size: 47px;

    &.subtitle2 {
      font-size: 47px;
      margin-top: 0;
      line-height: 0.7em
    }
  }
  .line {
    font-size: 28px;
  }
  .button {
    font-size: 28px;
  }
  .row {
    height: 359px;
  }
}
</style>
