<template>
  <div class="row">
    <div
      v-for="(tile, index) in partnerTiles"
      :key="tile.id"
      class="col-md-3 col-sm-6 pb5"
    >
      <a v-if="isExternalLink(tile.link)" :href="tile.link" target="_blank">
        <link-tile
          :background-image-url="tile.backgroundImageUrl"
          :alt="tile.imageAltText"
          :class="{ 'tile-link-title': true, 'background-primary': tile.backgroundColor === 'primary', 'background-secondary': tile.backgroundColor === 'secondary' }"
          :image-size="{ width: 1000, height: 1000 }"
        >
          <template v-slot:title>
            <div class="title-wrapper">
              <div v-html="tile.text" />
            </div>
          </template>
          <template v-if="tile.buttonText" v-slot:content>
            <div class="content-wrapper">
              <span class="tile-price" v-html="tile.buttonText" />
            </div>
          </template>
        </link-tile>
      </a>
      <router-link v-else :to="{ path: tile.link, query: fixedUrlQuery }">
        <link-tile
          :background-image-url="tile.backgroundImageUrl"
          :alt="tile.imageAltText"
          :class="{ 'tile-link-title': true, 'background-primary': tile.backgroundColor === 'primary', 'background-secondary': tile.backgroundColor === 'secondary' }"
          :image-size="{ width: 1000, height: 1000 }"
        >
          <template v-slot:title>
            <div class="title-wrapper">
              <div v-html="tile.text" />
            </div>
          </template>
          <template v-if="tile.buttonText" v-slot:content>
            <div class="content-wrapper">
              <span class="tile-price" v-html="tile.buttonText" />
            </div>
          </template>
        </link-tile>
      </router-link>
    </div>
  </div>
</template>

<script>
import LinkTile from 'theme/components/theme/anyrent/LinkTile';
import CustomLinkTile from '../../anyrent/CustomLinkTile.vue';
import FixedFilters from '@vue-storefront/core/mixins/FixedFilters';

export default {
  components: { LinkTile, CustomLinkTile },
  props: {
    partnerTiles: {
      type: Array,
      default: () => ([])
    }
  },
  mixins: [FixedFilters],
  methods: {
    isExternalLink (link) {
      return /^(https?:\/\/|www\.)/.test(link);
    }
  }
};

</script>
<style lang='scss' scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';

$color-partner: color(partner);
$color-darken-partner-10: color(darken-partner-10);

.background {
  transition: opacity .15s ease-in-out;
  &:hover,
  &:focus {
    opacity: 0.8;
  }
}

.background-primary {
  background-color: $color-partner;
  @extend .background;
}

.background-secondary {
  background-color: #b5ab9e;
  @extend .background;
}

.tile-price {
  display: inline-block;
  background-color: $color-partner;
  font-weight: normal;
  font-size: 22px;
  margin-top: 20px;
  color: #ffffff;
  font-weight: 700;
  border-radius: 24px;
  padding: 6px 12px;
  box-sizing: border-box;
}
.tile-price {
  font-family: TTCommons-Light;
  margin-bottom: 10%;
}

@media (max-width: 572px) {
  .tile-price {
    margin-top: 50px;
  }
}

@media (max-width: 75em) {
  .tile-price {
    font-size: 18px;
  }
  .tile-link-title {
    font-size: 32px;
  }
}

.vertical-image::v-deep img {
  height: auto;
  width: 100%;
}

.tile-link-title {
  z-index: 0;
}

.tile-link-title::v-deep .tile-content {
  padding-bottom: 20%;
  justify-content: end;
}

.tile-link-title::v-deep .tile-content .tile-price {
  min-width: 120px;
}

.title-wrapper {
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  font-size: 32px;
}

.content-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: end;
  padding-bottom: 20px;
  justify-content: center;
}

.tile-price {
  font-family: TTCommons-Light;
  margin-bottom: 6%;
}

.xski-tile::v-deep img {
  height: calc(100% + 10px);
  top: 50%;
}

.ski-tile::v-deep img {
  height: calc(100% + 10px);
  padding-left: 30%;
}

.bike-tile::v-deep img {
  width: calc(100% + 55px);
  top: 12%;
  left: 60%;
}

@media (max-width: 572px) {
  .tile-link-title::v-deep .tile-content {
    padding-bottom: 0;
    justify-content: center;
  }

  .tile-price {
    margin-bottom: 10%;
  }
}

@media (max-width: 75em) {
  .tile-price {
    font-size: 18px;
  }
}
</style>
